<template>
  <div>
    <!--Breadcrumb-->
    <section v-if="filterList.length > 0">
      <Breadcrumb
        :title="this.filterList[0].name"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: true, url: '/city', text: 'Şehirler' },
        ]"
      />
    </section>
    <!--/Breadcrumb-->
    <section class="sptb" style="min-height: 590px">
      <div class="container">
        <div class="row" v-if="filterList.length > 0">
          <div class="col-12 mb-5">
            <router-link
              :to="'/city/' + this.filterList[0].shortname + '/expert'"
              class="btn btn-primary w-100"
              >{{ filterList[0].name }} Tüm Terapistler</router-link
            >
          </div>
          <template v-if="filterList[0].districts.length == 0">
            <div class="row text-center">
              <h2>Şehir Bulunamadı!</h2>
            </div>
          </template>
          <div
            @v-if="filterList != null"
            class="col-lg-3 col-md-12"
            v-for="(item, i) in filterList[0].districts"
            :key="'cityCard' + i"
          >
            <router-link
              :to="'/city/' + filterList[0].shortname + '/' + item.shortname"
            >
              <div class="card">
                <div class="card-body">
                  <div class="item-box text-center">
                    <div
                      class="
                        bg-primary-transparent
                        icon-bg icon-service
                        text-primary
                      "
                    >
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="item-box-wrap">
                      <h5 class="mb-2">{{ item.name }}</h5>
                      <p class="text-muted mb-0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;

    let cityInfo = {};

    this.$store.dispatch("citysGet", cityInfo).then((value) => {
      this.cityList = value.list;
      this.filter(this.$route.params.city);
    });
  },
  data() {
    return {
      filterList: [],
      cityList: [],
      currentList: [],
      title: "",
      description: "",
    };
  },
  components: { Breadcrumb },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    filter(item) {
      this.filterList = this.cityList.filter(
        (country) => country.shortname == item
      );
      this.title =
        this.filterList[0].name + " İlçe Listesi - Terapi Vitrini";
      this.description =
        this.filterList[0].name +
        " - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz";
      this.$store.dispatch("setMetaContent", [
        this.title,
        this.description,
        "",
        "",
        "",
      ]);
    },
  },
  mounted() {
    this.backToTop();
  },
};
</script>
        